.forgotPassword {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(231, 231, 231);
}

.forgotPassword > form {
  display: flex;
  width: 20vw;
  flex-direction: column;
  padding: 3vmax;
  background-color: white;
  font: 300 1vmax 'Roboto';
}

.forgotPassword > form > div {
  padding: 1vmax 0;
}

.forgotPassword > form > input:nth-of-type(1) {
  padding: 0.7vmax;
  margin-bottom: 25px;
}

.forgotPassword > form > input:nth-of-type(2) {
  border: none;
  background-color: tomato;
  color: white;
  font: 300 0.9vmax 'Roboto';
  width: 100%;
  padding: 0.8vmax;
  cursor: pointer;
  transition: all 0.5s;
  border-radius: 4px;
  outline: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.219);
}

.forgotPasswordHide {
  position: absolute;
  visibility: hidden;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%) scale(0);
  transition: all 0.2s;
  padding: 5vmax;
}

.forgotPasswordShow {
  padding: 5vmax;
  background-color: rgba(0, 0, 0, 0.623);
  position: absolute;
  visibility: visible;
  transform: translateX(-50%) translateY(-50%) scale(1);
  transition: all 0.2s;
  top: 50%;
  left: 50%;
}
