footer {
  display: flex;
  align-items: center;
  color: white;
  background-color: rgb(34, 33, 33);
  padding: 2vmax;
  margin-top: 10vmax;
}

.leftFooter {
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1vmax;
}

.leftFooter > h4 {
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.leftFooter > p {
  text-align: center;
  font-family: 'Lucida Sans', 'lucida Sans Regular', 'Lucida Sans',
    'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva,
    Verdana, sans-serif;
}

.leftFooter > img {
  width: 10vmax;
  margin: 1vmax;
  cursor: pointer;
}

.midFooter {
  width: 60%;
  text-align: center;
}

.midFooter > h1 {
  font-size: 4vmax;
  font-family: 'Roboto';
  color: #eb4034;
}

.midFooter > p {
  max-width: 60%;
  margin: 1vmax auto;
}

.rightFooter {
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rightFooter > h4 {
  font-family: 'Roboto';
  font-size: 1.4vmax;
  text-decoration: underline;
}
