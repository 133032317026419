* {
  margin: 0;
  scroll-behavior: smooth;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.greenColor {
  color: green;
}

.redColor {
  color: red;
}

.menuBurgerItem {
  background-color: grey !important;
}
