.alertDialog {
  position: absolute;
  z-index: 2000;
  display: flex;
  left: 50%;
  align-items: center;
  background-color: rgb(201, 53, 53);
  border-radius: 5px;
  box-shadow: 0 0 6px 0px grey;
  font-family: Arial, Helvetica, sans-serif;
  transform: translateY(-200%);
  transition: all 1s;
  margin: 2vw 2vw 0 0;
}

.errorIcon {
  color: white;
  display: flex;
  align-items: center;
  padding: 10px 10px;
  border-right: 2px solid rgba(133, 0, 0, 0.2);
}

.closeIcon {
  display: inline-block;
  line-height: 0.5;
  font-size: 25px;
  font-weight: 700;
  color: white;
}

.alertMessage {
  display: flex;
  padding: 10px 20px;
  line-height: 0.5;
  color: white;
  white-space: nowrap;
}
